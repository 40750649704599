<template>
  <div class="account__container">
    <div class="row m-5">
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-6">
            <div class="form__container">
              <div class="form-group">
                <label for="start_date" class="form__label">Start Time:</label>
                <b-form-datepicker id="start_date" v-model="choose.start_date" class="mb-2" size="lg"></b-form-datepicker>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form__container margin-bottom">
              <div class="form-group">
                <label for="end_date" class="form__label">End Time:</label>
                <b-form-datepicker id="end_date" v-model="choose.end_date" class="mb-2" size="lg"></b-form-datepicker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-3">
        <button type="button" class="btn button button-cta cta" @click.prevent="GenerateDateStartEnd">Populate</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <schedule-designs></schedule-designs>
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleDesigns from "../ScheduleDesigns";
import {mapActions, mapMutations, mapState, mapGetters} from "vuex";
export default {
  name: "AccountLoanGenerate",
  components: {ScheduleDesigns},
  data () {
    return {
      choose: {
        start_date: this.minimum ,
        end_date: this.maximum,
      },
      users: this.user ? this.user : {},
      multiples: [],
    }
  },
  methods: {
    ...mapState({
      pdfState: 'setup/pdf'
    }),
    ...mapActions({
      pdfJoins: 'setup/pdfJoins'
    }),
    ...mapMutations({
      PDF: 'setup/pdf'
    }),
    GenerateDateStartEnd () {

      let request_amount, interest, balance

      this.pdfJoins()

      if ((new Date(this.choose.start_date) === "Invalid Date"
              && isNaN(new Date(this.choose.start_date))) ||
          new Date(this.choose.end_date) === "Invalid Date"
          && isNaN(new Date(this.choose.end_date))) {
        this.$toastr.w('not a valid date, choosen')
        return false
      }

      if (new Date(this.choose.end_date) <= new Date(this.choose.start_date)) {
        this.$toastr.w('end date must be greater or than start date')
        return false
      }
      if (this.loans.length) {
        console.log(this.loans)
        request_amount = this.loans.map(o => o.requested_amt).reduce((a, c) => { return a + c })
        interest = this.loans.map(o => o.loaninterest).reduce((a, c) => { return a + c })
        balance = request_amount + interest
        this.PDF({
          date: this.loans.length ? this.loans[0].mature_date : new Date(),
          debit: this.loans.length ? request_amount : 0,
          description: this.loans.length ? `Loan Disbursement of amount
                  ${request_amount}` : '',
          credit: 0,
          balance: this.loans.length ? -(request_amount) : 0,
        })
        this.PDF({
          date: this.loans.length ? this.loans[0].mature_date : new Date(),
          debit: this.loans.length ? interest : 0,
          description: this.loans.length ? `Loan Interest Receivable
                  ${interest}` : '',
          credit: 0,
          balance: this.loans.length ? -(interest + request_amount) : 0,
        })
        let vm = this
        this.loans.map(value => {
          value.loan_schedule.map(val => {
            if (val.paid_yn) {
              if (new Date(this.choose.start_date) <= new Date(val.schddate)
                  && new Date(this.choose.end_date) >= new Date(val.schddate)) {
                balance = val.paid_yn ? balance - val.install_amount : balance
                vm.PDF({
                  date: val.schddate,
                  debit: 0,
                  description: val.paid_yn ? `Amount paid is ${val.install_amount}` : '',
                  credit: val.paid_yn ? val.install_amount : -(val.install_amount),
                  balance: balance,
                })
              }
            }
          })
        })
      }
    }
  },
  props:{
    schedule: {
      type: [Array, Object],
      required: true
    },
    minimum: {
      type: [String, Number],
      required: true
    },
    maximum: {
      type: [String, Number],
      required: true
    },
  },
  computed: {
    ...mapGetters({
      pdf: 'setup/pdf',
      loans: 'loan/loans'
    }),
  }
}
</script>

<style scoped>

</style>