<template>
  <admin-default v-slot:admin>
    <div class="account">
      <div class="container container-resize">
        <div class="row border-bottom pb-5">
          <div class="col-md-9"><h1 class="application__secondary--title">Account Statement</h1></div>
          <div class="col-6 col-md-3">
            <h1 class="application__main--title">Welcome,</h1>
            <h1 class="application__tertiary--title">{{ user.name }}</h1>
          </div>
        </div>
      </div>
    <span v-if="schedule.length">
      <account-loan-generate :schedule="schedule" :maximum="maximum" :minimum="minimum" v-if="maximum && minimum"></account-loan-generate>
    </span>

    </div>
  </admin-default>
</template>

<script>


import AdminDefault from "../navigate/AdminDefault";
import {mapGetters} from "vuex";
import AccountLoanGenerate from "./pages/post/AccountLoanGenerate";
export default {
  name: "Account",
  components: {AccountLoanGenerate, AdminDefault},
  data () {
    return {
      users: this.user ? this.user : {},
      multiples: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      loans: 'loan/loans',
      schedule: 'loan/schedule_loans',
    }),
    schedules () {
      return this.loans[0]
    },

    minimum () {
      let sl, aRR
      aRR = []
      if (this.schedule.length) {
        this.schedule.map(value => {
          aRR.push(value.loan_schedule.schddate)
        })
        sl = aRR.sort((a,b)=>a-b)[0]
            // max= dates.slice(-1)[0]

            // console.log(sl)
      }
      return sl
    },
    maximum () {
      let sl, aRR
      aRR = []
      if (this.schedule.length) {
        this.schedule.map(value => {
          aRR.push(value.loan_schedule.schddate)
        })
        sl = aRR.slice(-1)[0],
            // max= dates.slice(-1)[0]

            console.log(sl)
      }
      return sl
    }

  },
}
</script>

<style scoped>

</style>
